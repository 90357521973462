<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="id" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.id" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18"></a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<a-table style="margin-top: 10px;" :dataSource="list" :columns="columns" :pagination="pagination" rowKey="id">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'isDistribution'">
						{{ record.isDistribution ? '是' : '否' }}
					</template>
					<template v-if="column.key === 'isIndependent'">
						{{ record.isIndependent ? '是' : '否' }}
					</template>
					<template v-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
							</a-button>
							<template #overlay>
								<a-menu>
									<div @click="onEdit(record, true)">
										<a-menu-item>
											查看
										</a-menu-item>
									</div>
									<div v-permission="['member_distributor_ticket_set']" @click="onEdit(record, false)">
										<a-menu-item>
											分销配置
										</a-menu-item>
									</div>
									<!-- <div @click="onDelete(record)">
										<a-menu-item >
											刪除
										</a-menu-item>
									</div> -->
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>
			
			<a-modal v-model:visible="showModal" title="分销配置" width="850px" @ok="onSubmit">
				<a-spin :spinning="distributionLevelLoading">
					<a-form ref="form" name="form" :model="modelRef" :labelCol="{ span: 4 }">
						<a-form-item label="是否参与分销" name="isDistribution"
							:rules="[{required: true, message: '必填项不允许为空'}]"
						>
							<a-radio-group style="margin-top: 4px;" :disabled="isSee" v-model:value="modelRef.isDistribution" @change="onDistChange">
								<a-radio :value="1">参与</a-radio>
								<a-radio :value="0">不参与</a-radio>
							</a-radio-group>
							<div style="color: red;">参与分销后，购买该影城的影票将产生分销佣金</div>
						</a-form-item>
											
						<a-form-item v-if="modelRef.isDistribution" label="是否开启独立分销" name="isIndependent"
							:rules="[{required: true, message: '必填项不允许为空'}]"
						>
							<a-radio-group style="margin-top: 4px;" :disabled="isSee" v-model:value="modelRef.isIndependent" @change="onLevelChange">
								<a-radio :value="1">开启</a-radio>
								<a-radio :value="0">关闭</a-radio>
							</a-radio-group>
							<div style="color: red;">不开启则使用默认佣金设置规则；开启则该影院使用独立佣金比例规则，不受分销商等级比例限制。</div>
						</a-form-item>
						
						<a-form-item label="分销配置" v-if="modelRef.isIndependent">
							<a-table rowKey="id" :dataSource="levelList" :columns="columnsScale" :pagination="false">
								<template #bodyCell="{column, record, index}">
									<template v-if="column.key === 'oneLevelScale'">
										<a-input-number :disabled="isSee" v-model:value="levelList[index].oneLevelScale" :precision="3" :min="0" :max="onMaxScoreInput(index, 'oneLevelScale')"></a-input-number> %
									</template>
									<template v-if="column.key === 'twoLevelScale'">
										<a-input-number :disabled="isSee" v-model:value="levelList[index].twoLevelScale" :precision="3" :min="0" :max="onMaxScoreInput(index, 'twoLevelScale')"></a-input-number> %
									</template>
									<template v-if="column.key === 'storekeeperLevelScale'">
										<a-input-number :disabled="isSee" v-model:value="levelList[index].storekeeperLevelScale" :precision="3" :min="0" :max="onMaxScoreInput(index, 'storekeeperLevelScale')"></a-input-number> %
									</template>
								</template>
							</a-table>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import { getDistributionLevelList, cinemaDistributionConfigDetail, cinemaDistributionConfig } from '@/service/modules/distribution.js';
	export default {
		components: { Icon },
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					//organizationId: 0,
					id: 0,
				},
				searchData: {},
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'name'
				}, {
					title: '是否参与分销',
					key: 'isDistribution'
				}, {
					title: '是否独立分销',
					key: 'isIndependent'
				}, {
					title: '操作',
					key: 'action'
				}],
				distributionLevelLoading: false,
				levelList: [],
				columnsScale: [{
					title: '等级名称',
					dataIndex: 'title'
				}, {
					title: '自购分销比例',
					key: 'oneLevelScale'
				}, {
					title: '邀请人分销比例',
					key: 'twoLevelScale'
				}, {
					title: '团队长分红比例',
					key: 'storekeeperLevelScale'
				}],
				isSee: false,
				showModal: false,
				modelRef: {
					isDistribution: 0,
					isIndependent: 0
				},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				oneMoneyScaleMax: 99,
				twoMoneyScaleMax: 99,
				id: 0
			}
		},
		created() {
			this.getOrganizationList();
			//this.onSearch();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.id = this.searchData.id ? this.searchData.id : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.form.validateFields().then(async ()=> {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.cinemaDistributionConfig = this.levelList.map(item => {
						return {
							distributionId: item.id,
							oneLevelScale: item.oneLevelScale,
							twoLevelScale: item.twoLevelScale,
							storekeeperLevelScale: item.storekeeperLevelScale
						}
					})
					this.distributionLevelLoading = true;
					try {
						let ret = await cinemaDistributionConfig(postData);
						this.distributionLevelLoading = false;
						if (ret.code === 200) {
							this.$message.success('修改成功');
							this.showModal = false;
							this.getData();
						}
					} catch(e) {
						this.distributionLevelLoading = false;
					}
				})
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getDistributionLevelList() {
				this.distributionLevelLoading = true;
				try {
					let ret = await getDistributionLevelList({
						page: 1,
						pageSize: 999
					});
					this.distributionLevelLoading = false;
					if (ret.code === 200) {
						let list = ret.data.list.map(item => {
							return {
								id: item.id,
								title: item.title,
								oneLevelScale: 0,
								twoLevelScale: 0,
								storekeeperLevelScale: 0
							}
						});
						if (this.levelList.length) {
							list.forEach(item => {
								this.levelList.forEach(subItem => {
									if(subItem.id === item.id) {
										item.oneLevelScale = subItem.oneLevelScale;
										item.twoLevelScale = subItem.twoLevelScale;
										item.storekeeperLevelScale = subItem.storekeeperLevelScale;
									}
								})
							})
						}
						this.levelList = list;
					}
				} catch(e) {
					this.distributionLevelLoading = false;
				}
			},
			async onEdit(item, isSee) {
				this.levelList = [];
				this.loading = true;
				try {
					let ret = await cinemaDistributionConfigDetail({
						cinemaId: item.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.isSee = isSee;
						this.showModal = true;
						this.modelRef = ret.data;
						this.modelRef.id = item.id;
						if (ret.data.cinemaDistributionConfigList && ret.data.cinemaDistributionConfigList.length) {
							this.levelList = ret.data.cinemaDistributionConfigList.map(item => {
								return {
									id: item.distributionId,
									title: item.distributionTitle,
									oneLevelScale: item.oneLevelScale || 0,
									twoLevelScale: item.twoLevelScale || 0,
									storekeeperLevelScale: item.storekeeperLevelScale || 0,
								}
							});
							// this.getDistributionLevelList();
						}
						delete this.modelRef.cinemaDistributionConfigList;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onLevelChange() {
				if (!this.levelList.length) {
					this.getDistributionLevelList();
				}
			},
			onDistChange() {
				this.modelRef.isIndependent = 0;
			},
			onMaxScoreInput(index, key) {
				// let price = price || 0;
				// this.modelRef.nowPrice
				let count = 0;
				this.levelList.forEach((item, i) => {
					if (index === i) {
						if (key === 'oneLevelScale') {
							count = count + Number(item.twoLevelScale) + Number(item.storekeeperMoneyScale);
						}
						if (key === 'twoLevelScale') {
							count = count + Number(item.oneLevelScale) + Number(item.storekeeperMoneyScale);
						}
						if (key === 'storekeeperLevelScale') {
							count = count + Number(item.oneLevelScale) + Number(item.twoLevelScale);
						}
					}
				});
				let max = 99 - (count || 0);
				if (max >= 0) {
					return Number(max);
				} else {
					if (key) {
						return 0;
					} else {
						return Number(max);
					}
				}
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
