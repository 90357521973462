import { send } from "../index";


export function getBaseUserList(data, opts = {}) {
	//  用戶列表
  return send({
    url: "/admin/baseUser/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDistributorList(data, opts = {}) {
	//  分销商列表
  return send({
    url: "/admin/distribution/distributorList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDistributorOrderList(data, opts = {}) {
	//  分销商订单列表
  return send({
    url: "/admin/distribution/orderList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelves(data, opts = {}) {
	//  启用分销身份
  return send({
    url: "/admin/distribution/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelves(data, opts = {}) {
	//  禁用分销身份
  return send({
    url: "/admin/distribution/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getSonTree(data, opts = {}) {
	//  下级团队列表
  return send({
    url: "/admin/baseUser/recommendList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function bindRecommend(data, opts = {}) {
	//  修改推荐人
  return send({
    url: "/admin/baseUser/bindRecommend.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function bindStore(data, opts = {}) {
	//  修改绑定门店
  return send({
    url: "/admin/baseUser/bindStore.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateDistribution(data, opts = {}) {
	//  修改分销商分销等级
  return send({
    url: "/admin/baseUser/updateDistribution.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDistributionLevelList(data, opts = {}) {
	//  分销等级列表
  return send({
    url: "/admin/distribution/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveDistributionLevel(data, opts = {}) {
	//  新增分销等级
  return send({
    url: "/admin/distribution/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateDistributionLevel(data, opts = {}) {
	//  修改分销等级
  return send({
    url: "/admin/distribution/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDistributionLevelDetail(data, opts = {}) {
	//  分销等级详情
  return send({
    url: "/admin/distribution/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteDistributionLevel(data, opts = {}) {
	//  删除分销等级
  return send({
    url: "/admin/distribution/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDistributionSettingDetail(data, opts = {}) {
	//  修改分销设置
  return send({
    url: "/admin/distributionSetting/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateDistributionSetting(data, opts = {}) {
	//  修改分销设置
  return send({
    url: "/admin/distributionSetting/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cinemaDistributionConfigDetail(data, opts = {}) {
	//  影院分销设置详情
  return send({
    url: "/admin/cinemaDistributionConfig/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cinemaDistributionConfig(data, opts = {}) {
	//  修改分销设置
  return send({
    url: "/admin/cinemaDistributionConfig/setUp.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function distributorReport(data, opts = {}) {
	//  分销报表
  return send({
    url: "/admin/distribution/distributorReport.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function distributorReportOrderDetail(data, opts = {}) {
	//  分销报表
  return send({
    url: "/admin/distribution/orderDetail.do",
    method: "POST",
    data,
    ...opts,
  });
}